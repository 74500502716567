<template>
  <div class="side-bar-menu">
    <a-menu style="width: 180px" :openKeys.sync="openKeys" v-model="activeKey" mode="inline" border="none">
      <a-menu-item
        style="height: 40px; line-height: 40px;"
        :key="`parent${menu.name}`"
        v-for="menu in menus"
        v-show="!menu.children && !menu.hidden"
        @click="titleClick(menu)"
      >
        <a-icon :type="menu.meta && menu.meta.icon" />
        <span class="font14">{{ menu.meta && menu.meta.title }}</span>
      </a-menu-item>

      <a-sub-menu :key="menu.name" v-for="menu in menus" v-show="menu.children && !menu.hidden">
        <span slot="title" class="font14">
          <a-icon :type="menu.meta && menu.meta.icon" />
          <span>{{ menu.meta && menu.meta.title }}</span>
        </span>
        <a-menu-item
          style="height: 32px; line-height: 32px; margin: 0;"
          :key="smenu.name"
          v-for="smenu in menu.children"
          v-show="!smenu.hidden && !smenu.children"
          @click="titleClick(smenu)"
        >
          {{ smenu.meta && smenu.meta.title }}
        </a-menu-item>
        <a-sub-menu
          :key="'k' + index"
          v-for="(tmenu, index) in menu.children"
          v-show="!tmenu.hidden && tmenu.children"
          :title="tmenu.meta.title"
        >
          <a-menu-item :key="kmenu.name" v-for="kmenu in tmenu.children" @click="titleClick(kmenu)">
            {{ kmenu.meta && kmenu.meta.title }}
          </a-menu-item>
        </a-sub-menu>
      </a-sub-menu>
    </a-menu>
  </div>
</template>

<script>
import { routes } from '@/router'
export default {
  data () {
    return {
      menus: [],
      activeKey: [this.$route.meta.activeName, `parent${this.$route.name}`],
      openKeys: []
    }
  },
  watch: {
    '$route.name': function (newVal) {
      if (newVal) {
        this.activeKey = [this.$route.meta.activeName, `parent${this.$route.name}`]
      }
    }
  },
  created () {
    this.setMenuList()
  },
  methods: {
    titleClick (menu) {
      if (menu.path) {
        console.log('menu.path', menu.path)
        this.$router.push(
          menu.path,
          e => {},
          e => {
            this.activeKey = [this.$route.meta.activeName, `parent${this.$route.name}`]
          }
        )
      }
    },
    setMenuList () {
      let tmp = []
      routes.forEach((value, indx) => {
        if (value.path === '/' && value.children[0]) {
          tmp.push(...value.children)
        }
      })
      tmp.sort((a, b) => {
        return a.order - b.order
      })
      this.$set(this, 'menus', tmp)
      console.log('menus', tmp)
      this.openKeys = this.menus.map((menu, index) => {
        return menu.name
      })
    }
  }
}
</script>

<style lang="less">
.side-bar-menu {
  padding-top: 10px;
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border: none;
  }
}
</style>
