import config from '@/libs/config'
const theme = {
  state: {
    refreshViewAlive: true,
    multiTab: config.multiTab
  },
  mutations: {
    TOGGLE_REFRESH_VIEW_ALIVE: (state, bool) => {
      state.refreshViewAlive = bool
    },
    TOGGLE_MULTITAB: (state, bool) => {
      state.multiTab = bool
    }
  },
  actions: {

  }
}

export default theme
