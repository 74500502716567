<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  provide () {
    return {
      reload: this.reload
    }
  },
  methods: {
    ...mapMutations(['TOGGLE_REFRESH_VIEW_ALIVE']),
    reload () {
      this.TOGGLE_REFRESH_VIEW_ALIVE(false)
      this.$nextTick(function () {
        this.TOGGLE_REFRESH_VIEW_ALIVE(true)
      })
    }
  }
}
</script>
<style lang="less">
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  font-size: 14px;
  height: 100%;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
