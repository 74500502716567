<template>
  <!-- <div :style="!$route.meta.hiddenHeaderContent ? 'margin: -24px -24px 0px;' : null"> -->
  <div class="page-view">
    <!-- <s-breadcrumb v-if="breadcrumbVisible"></s-breadcrumb> -->
    <div class="content">
      <div class="page-header-index-wide">
        <slot v-if="refreshViewAlive">
          <!-- keep-alive  -->
          <keep-alive v-if="multiTab && $route.meta.keepAlive" :max="20">
            <router-view :key="$route.fullPath" ref="content" />
          </keep-alive>
          <router-view v-else ref="content" />
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import Breadcrumb from '@/components/tools/Breadcrumb'
// import config from '@/libs/config'

export default {
  name: 'PageView',
  components: {
    // 's-breadcrumb': Breadcrumb
  },
  props: {
    avatar: {
      type: String,
      default: null
    },
    title: {
      type: [String, Boolean],
      default: true
    },
    logo: {
      type: String,
      default: null
    },
    directTabs: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      pageTitle: null,
      description: null,
      linkList: [],
      extraImage: '',
      search: false,
      tabs: {}
      // breadcrumbVisible: config.breadcrumb
    }
  },
  computed: {
    ...mapGetters(['multiTab', 'refreshViewAlive'])
  },
  mounted () {
    this.tabs = this.directTabs
    this.getPageMeta()
  },
  updated () {
    this.getPageMeta()
  },
  methods: {
    getPageMeta () {
      // eslint-disable-next-line
      this.pageTitle = (typeof(this.title) === 'string' || !this.title) ? this.title : this.$route.meta.title

      const content = this.$refs.content
      if (content) {
        if (content.pageMeta) {
          Object.assign(this, content.pageMeta)
        } else {
          this.description = content.description
          this.linkList = content.linkList
          this.extraImage = content.extraImage
          this.search = content.search === true
          this.tabs = content.tabs
        }
      }
    }
  }
}
</script>

<style lang="less">
.page-view{
  .content {
    background-color: #fff;
    /* margin: 0 12px 12px 12px; */
    // margin-top: 20px;
    padding: 12px;
    .link {
      margin-top: 16px;
      &:not(:empty) {
        margin-bottom: 16px;
      }
      a {
        margin-right: 32px;
        height: 24px;
        line-height: 24px;
        display: inline-block;
        i {
          font-size: 24px;
          margin-right: 8px;
          vertical-align: middle;
        }
        span {
          height: 24px;
          line-height: 24px;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }
  .page-menu-search {
    text-align: center;
    margin-bottom: 16px;
  }
  .page-menu-tabs {
    margin-top: 48px;
  }

  .extra-img {
    margin-top: -60px;
    text-align: center;
    width: 195px;

    img {
      width: 100%;
    }
  }

  .mobile {
    .extra-img{
      margin-top: 0;
      text-align: center;
      width: 96px;

      img{
        width: 100%;
      }
    }
  }
}
</style>
