import request from '@/utils/request'

const login = (params) => {
  return request({
    url: `/auth/login`,
    data: params,
    method: 'post'
  })
}

const auth = (token) => {
  return request({
    url: '/auth/auth/user',
    params: token,
    method: 'get'
  })
}
const authInfo = () => {
  return request({
    url: '/auth/user',
    method: 'get'
  })
}

const changePwd = (params) => {
  return request({
    url: `/auth/user/pwd`,
    data: params,
    method: 'put'
  })
}
const getMsgCode = (params) => {
  return request({
    url: `/auth/user/phone/code`,
    method: 'post',
    data: params
  })
}
const bindPhone = (params) => {
  return request({
    url: `/auth/user/phone`,
    method: 'put',
    data: params
  })
}
const codeChangePassword = (params) => {
  return request({
    url: `/auth/user/phone/pwd`,
    method: 'put',
    data: params
  })
}
const getPhoneLogin = (params) => {
  return request({
    url: `/auth/login/phone/code`,
    method: 'post',
    data: params
  })
}
export {
  login, // 用户登录
  auth, // 认证服务
  authInfo, // 获取用户信息
  changePwd, // 修改密码
  getMsgCode, // 获取验证码
  codeChangePassword, // 验证码修改密码
  bindPhone, // 绑定手机号
  getPhoneLogin
}
