// import '@babel/polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 引入公共样式
import '@/styles/index.less'

import '@/utils/antDesign'

// 权限控制
import '@/plus/action.js'
import '@/plus/basePermission.js'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
