<template>
  <div>
    <div class="container-head">
      <div class="head-main">
        <div class="header">
          <div class="title">{{ userInfo.storeName }}</div>
          <div class="service">客服电话：021-1234567 （周一至周五 9:00 - 18:00）</div>
        </div>
        <div>
          <a-dropdown>
            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
              欢迎您，{{ userInfo.nickname }} <a-icon type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a href="javascript:;" @click="logOut">退出登录</a>
              </a-menu-item>
              <a-menu-item>
                <a href="javascript:;" @click="changePwdFlag">修改密码</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </div>
    </div>
    <a-modal
      title="修改密码"
      :visible="storeVisible"
      @ok="handleSubmit"
      @cancel="cancelStore"
      ok-text="确认"
      cancel-text="取消"
      width="450px"
    >
      <a-form :form="form" @submit="handleSubmit" labelAlign="left">
        <a-form-item v-bind="formItemLayout" label="旧密码">
          <a-input
            placeholder="请输入旧密码"
            v-decorator="[
              'oldPwd',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入旧密码'
                  }
                ]
              }
            ]"
          />
        </a-form-item>
        <a-form-item v-bind="formItemLayout" label="新密码">
          <a-input-password
            placeholder="请输入新密码"
            v-decorator="[
              'newPwd',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入新密码'
                  }
                ]
              }
            ]"
          />
        </a-form-item>
        <a-form-item v-bind="formItemLayout" label="确认密码">
          <a-input-password
            placeholder="请输入新密码"
            v-decorator="[
              'new',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入新密码'
                  }
                ]
              }
            ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal
      title="手机号绑定"
      :visible="bindPhoneVisible"
      @ok="bindPhone"
      @cancel="cancelVisible"
      ok-text="确认"
      cancel-text="取消"
      width="450px"
      :maskClosable="false"
      :closable="false"
    >
      <a-form-model
        :model="bindPhoneForm"
        ref="ruleForm"
        :rules="rules"
        :label-col="formItemLayout.labelCol"
        :wrapper-col="formItemLayout.wrapperCol"
      >
        <a-form-model-item label="手机号" prop="phone">
          <a-input v-model="bindPhoneForm.phone" placeholder="请输入手机号" />
        </a-form-model-item>
        <a-form-model-item label="验证码" prop="code">
          <a-row :gutter="8">
            <a-col :span="12">
              <a-input placeholder="请输入验证码" v-model="bindPhoneForm.code" />
            </a-col>
            <a-col :span="12">
              <a-button @click="getCaptcha">{{ btnTitle }}</a-button>
            </a-col>
          </a-row>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { removeToken } from '@/utils/cookie'
import { changePwd, getMsgCode, bindPhone } from '@/serve/auth'
import md5 from 'md5'
export default {
  data () {
    return {
      btnTitle: '获取验证码',
      form: this.$form.createForm(this),
      userInfo: {},
      isBind: false,
      isFlag: false,
      storeVisible: false,
      timer: null,
      bindPhoneVisible: false,
      bindPhoneForm: {
        phone: '',
        code: ''
      },
      rules: {
        phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      },
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 5 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 }
        }
      }
    }
  },
  methods: {
    changePwdFlag () {
      this.storeVisible = true
    },
    cancelStore () {
      this.storeVisible = false
      this.form.resetFields()
    },
    handleSubmit (e) {
      e.preventDefault()
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          if (values.newPwd === values.oldPwd) {
            this.form.resetFields()
            return this.$message.error('新旧密码一致, 请重新输入!')
          }
          if (values.newPwd !== values.new) {
            return this.$message.error('两次输入新密码不一致, 请重新输入!')
          }
          let data = {
            newPwd: md5(values.newPwd),
            oldPwd: md5(values.oldPwd)
          }
          changePwd(data)
            .then(() => {
              this.storeVisible = false
              this.form.resetFields()
              this.$message.success('修改密码成功！')
              removeToken()
              sessionStorage.removeItem('sell_token')
              sessionStorage.removeItem('sell_userInfo')
              this.$router.push('/login')
            })
            .catch(() => {
              this.$message.error('修改密码失败！')
              this.form.resetFields()
            })
        }
      })
    },
    logOut () {
      let that = this
      this.$confirm({
        title: '提示',
        content: '真的要注销登录吗 ?',
        okText: '确定',
        cancelText: '取消',
        onOk () {
          removeToken()
          sessionStorage.removeItem('sell_token')
          sessionStorage.removeItem('sell_userInfo')
          that.$router.push('/login')
        },
        onCancel () {}
      })
    },
    // 绑定手机号
    bindPhone () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let params = {
            phone: this.bindPhoneForm.phone,
            code: this.bindPhoneForm.code,
            roleType: 'SELL'
          }
          bindPhone(params)
            .then(() => {
              this.$message.success('绑定成功！')
              this.bindPhoneVisible = false
              window.sessionStorage.setItem('isBind', true)
            })
            .catch(() => {
              this.isFlag = false
            })
        }
      })
    },
    cancelVisible () {
      this.$message.info('请先完成手机号绑定！')
    },
    // 获取验证码
    getCaptcha () {
      if (!this.bindPhoneForm.phone || !/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.bindPhoneForm.phone)) {
        return this.$message.error('请输入合法手机号')
      }
      if (this.isFlag) return
      this.isFlag = true
      let time = 60
      let params = {
        phone: this.bindPhoneForm.phone,
        type: 'PHONE_BOND',
        roleType: 'SELL'
      }
      getMsgCode(params)
        .then(() => {
          this.timer = setInterval(() => {
            if (time > 0) {
              time--
              this.btnTitle = `${time}秒后重试`
            } else {
              this.btnTitle = '点击获取验证码'
              this.isFlag = false
              clearInterval(this.timer)
            }
          }, 1000)
        })
        .catch(err => {
          if ((err.status === 404 || err.status === 400) && !err.data.success) {
            this.$message.error(err.data.message)
          }
          this.btnTitle = '点击获取验证码'
          this.isFlag = false
          clearInterval(this.timer)
        })
    }
  },
  created () {
    this.userInfo = JSON.parse(sessionStorage.getItem('sell_userInfo'))
    let phone = this.userInfo.storeMobile
    if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(phone) && !window.sessionStorage.getItem('isBind')) {
      this.bindPhoneVisible = true
    }
  }
}
</script>

<style lang="less" scoped>
.container-head {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background: #e69b47;
  /deep/ .ant-dropdown-link {
    color: #f7f4f4;
  }
  .head-main {
    width: 1300px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .header {
      display: flex;
      .title {
        color: #ffffff;
        font-size: 25px;
      }
      .service {
        margin-left: 40px;
        color: #f7f4f4;
      }
    }
  }
}
</style>
