import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

/**
 * == asyncRoutes =========
 * 1、导出动态路由asyncRoutes
 * 2、在store的权限控制文件/src/store/modules/permission里面控制与角色信息一一匹配
 * 3、在/src/permission.js文件里实现动态路由加载
 *
 * == constantRoutes ======
 * 基础路由不做动态加载，例如login、regist、404等
 * 不需要权限控制即可使用 constantRoutes 实现所有路由
 *
 * == params ==============
 * hideChildrenInMenu 用于隐藏不需要在菜单中展示的子路由。用法可以查看 分步表单 的配置。
 * hidden 可以在菜单中不展示这个路由，包括子路由。效果可以查看 other 下的路由配置。
 * meta.title 和 meta.icon分别代表生成菜单项的文本和图标。
 * meta.permission 用来配置这个路由的权限，如果配置了将会验证当前用户的权限，并决定是否展示 *（默认情况下）。
 * meta.hidden 可以强制子菜单不显示在菜单上(和父级 hideChildrenInMenu 配合)
 * meta.hiddenHeaderContent 可以强制当前页面不显示 PageHeader 组件中的页面带的 面包屑和页面标题栏
 *
 */

// 动态路由
let asyncRoutes = []
const asyncContexts = require.context('./asyncRoutes', true, /\.js$/)
asyncContexts.keys().forEach(key => {
  const modRoutes = asyncContexts(key).default
  if (!key.includes('index') && modRoutes instanceof Array) {
    asyncRoutes = asyncRoutes.concat(modRoutes)
  }
})

// 基础路由
let constantRoutes = []
const constantContexts = require.context('./constantRoutes', true, /\.js$/)
constantContexts.keys().forEach(key => {
  const modRoutes = constantContexts(key).default
  if (!key.includes('index') && modRoutes instanceof Array) {
    constantRoutes = constantRoutes.concat(modRoutes)
  }
})

// 不需要权限验证
const routes = constantRoutes.concat(asyncRoutes)
// const routes = asyncRoutes

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes
})

export {
  routes,
  asyncRoutes
  // constantRoutes
}
