import { login, authInfo } from '@/serve/auth'
import { setToken, removeToken } from '@/utils/cookie'
const user = {
  state: {
    token: '',
    info: {} // 用户信息
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
      setToken(token)
    },
    REMOVE_TOKEN: (state, token) => {
      state.token = null
      removeToken()
    },
    SET_INFO: (state, info) => {
      state.info = info
    }
  },
  actions: {
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(response => {
          if (typeof response.token === 'string') {
            commit('SET_TOKEN', response.token)
            sessionStorage.setItem('sell_token', response.token)
            authInfo().then(res => {
              commit('SET_INFO', res)
              window.sessionStorage.setItem('sell_userInfo', JSON.stringify(res))
              resolve()
            })
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    GetInfo ({ commit }, token) {
      commit('SET_TOKEN', token)
      window.sessionStorage.setItem('sell_token', token)
      return new Promise((resolve, reject) => {
        authInfo().then(response => {
          if (response) {
            commit('SET_INFO', response)
            window.sessionStorage.setItem('sell_userInfo', JSON.stringify(response))
            resolve(response)
          } else {
            commit('SET_INFO', {})
          }
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default user
