<template>
  <div class="footer mt30">
    <!-- <div class="logos">
      <ul>
        <li>
          <img src="../../assets/imgs/fortune.png" width="100%" />
        </li>
        <li>
          <img src="../../assets/imgs/logo.png" width="100%" />
        </li>
        <li>家长的安心之选，选安心产品是我们的责任</li>
      </ul>
    </div> -->
    <!-- <div class="links">
      <dl>
        <dt>快速连接</dt>
        <dd>
          <a href="javascript:;">关于我们</a>
        </dd>
        <dd>
          <a href="javascript:;">联系我们</a>
        </dd>
        <dd>
          <a href="javascript:;">配送服务</a>
        </dd>
        <dd>
          <a href="javascript:;">服务条款</a>
        </dd>
      </dl>
      <dl>
        <dt>购买指南</dt>
        <dd>
          <a href="javascript:;">关于支付</a>
        </dd>
        <dd>
          <a href="javascript:;">关于配送</a>
        </dd>
        <dd>
          <a href="javascript:;">关于退货</a>
        </dd>
        <dd>
          <a href="javascript:;">关于售后</a>
        </dd>
      </dl>
      <dl>
        <dt>专区推荐</dt>
        <dd>
          <a href="javascript:;">海富园</a>
        </dd>
        <dd>
          <a href="javascript:;">精英园</a>
        </dd>
        <dd>
          <a href="javascript:;">中小学专区</a>
        </dd>
        <dd>
          <a href="javascript:;">其他区</a>
        </dd>
      </dl>
    </div> -->
    <!-- <div class="contact">
      <div class="title">联系电话</div>
      <div class="phone">
        <i class="iconfont icon-dianhua mr10" />
        <span>400-120-2100</span>
      </div>
      <div class="point">
        <i class="iconfont icon-biaodian mr10" />
        <span>上海市闵行区某某路某某弄12号1楼101室</span>
      </div>
      <div class="email">
        <i class="iconfont icon-EmailHili mr10" />
        <span>service@lexiangspace.com</span>
      </div>
    </div> -->
    <p>Copyright © 2019 - 2020 乐享空间版权所有 | 沪ICP备20000611号-1</p>
  </div>
</template>
<script>
export default {
  name: 'footBar'
}
</script>
<style lang="less" scoped>
.footer {
  border-top: 3px solid #e69b47;
  padding-top: 30px;
  text-align: center;
  background: #fff;

  .logos,
  .links,
  .contact {
    width: 300px;
    display: inline-block;
  }
  .links {
    width: 400px;
  }

  .logos {
    text-align: left;
    ul {
      padding: 0;
      li {
        width: 150px;
        margin-bottom: 20px;
      }
    }
  }
  .links {
    text-align: center;
    dl {
      display: inline-block;
      margin-right: 100px;
      &:last-child {
        margin-right: 0;
      }
      dt {
        font-weight: 700;
        margin-bottom: 20px;
      }
      dd > a{
        color: #5f5f5f;
      }
    }
  }

  .contact {
    text-align: left;
    margin-left: 130px;
    .title {
      font-weight: 700;
      margin-bottom: 5px;
    }
    .phone {
      i {
        font-size: 27px;
        color: #e69b47;
      }
      span {
        font-weight: 900;
        font-size: 18px;
        color: #e69b47;
      }
    }

    .phone,
    .point,
    .email {
      margin-bottom: 16px;
      img {
        margin-right: 10px;
      }
    }
  }
}
</style>
