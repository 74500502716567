export default [
  {
    path: '/',
    hidden: true,
    children: [
      {
        path: '/',
        name: 'login',
        hidden: true,
        redirect: '/login'
      }
    ]
  }
]
